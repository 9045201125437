export const basedata = {
    "subscribe": [
        {
            "title": "",
            "price": "500",
            "priceId": "price_1LWmRlKAsFsFzn5n5yJwHGfC",
            "buttonText": "Sign up for free",
            "buttonVariant": "outlined"
        },
        {
            "title": "",
            "price": "1000",
            "priceId": "price_1LWmRmKAsFsFzn5n15eHFqmV",
            "buttonText": "Get started",
            "buttonVariant": "contained"
        },
        {
            "title": "",
            "price": "3000",
            "priceId": "price_1LWmRlKAsFsFzn5nIUB0BvI5",
            "buttonText": "Contact us",
            "buttonVariant": "outlined"
        },
        {
            "title": "",
            "price": "5000",
            "priceId": "price_1LWmRlKAsFsFzn5ng08ITzDi",
            "buttonText": "Contact us",
            "buttonVariant": "outlined"
        },
        {
            "title": "",
            "price": "10000",
            "priceId": "price_1LWmRlKAsFsFzn5nqdP7j4NR",
            "buttonText": "Contact us",
            "buttonVariant": "outlined"
        },
        {
            "title": "",
            "price": "30000",
            "priceId": "price_1LWmRlKAsFsFzn5nntj73SOI",
            "buttonText": "Contact us",
            "buttonVariant": "outlined"
        }
    ]
}
